<template>
  <div class="business_sub_page">
    <div class="topic_panel">
      <div class="topic_desc">
        商务合作
      </div>
      <div class="topic_desc_en">
        BUSSINESS COOPERATION
      </div>
      <div class="topic_desc_line"></div>
    </div>
    <div class="main_panel">

      <div>
        <div class="contact_us">{{ businessInfo.labelName }}</div>
        <div class="contact_container">

          <div class="contact_info">
            <div class="info_detail" v-if="businessInfo.image">
              <img :src="businessInfo.image" class="contact_image" />
            </div>
            <el-divider></el-divider>
            <div class="info_detail">
              <div class="icon_bg">
                <img src="./images/phone.png" class="info_image" />
              </div>
              <div class="contact_tel">
                <div class="detail_label">
                  电话：
                </div>
                <div class="detail_desc">
                  {{ businessInfo.telNum }}
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="info_detail">
              <div class="icon_bg">
                <img src="./images/wechat.png" class="info_image" />
              </div>
              <div class="contact_tel">
                <div class="detail_label">
                  微信：
                </div>
                <div class="detail_desc">
                  <div class="detail_desc">
                    {{ businessInfo.wechat }}
                  </div>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="info_detail">
              <div class="icon_bg">
                <img src="./images/mail.png" class="info_image" />
              </div>
              <div class="contact_tel">
                <div class="detail_label">
                  邮箱：
                </div>
                <div class="detail_desc">
                  业务咨询：{{ businessInfo.eMail }}
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="info_detail">
              <div class="icon_bg">
                <img src="./images/local.png" class="info_image" />
              </div>
              <div class="contact_tel">
                <div class="detail_label">
                  地址：
                </div>
                <div class="detail_desc">
                  <div v-for="address,index in businessInfo.address" :key="index" class="address">
                    {{ address }}
                  </div>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
          <div class="contact_banner">
            <div v-if="businessInfo.imageRight">
              <img :src="businessInfo.imageRight" class="banner_img" />
            </div>
          </div>
        </div>
      </div>
      <div class="contact_btn">
        <el-button type="primary" round @click="contactUs" style="background-color: #0086D1;">
          <span class="btn_label">{{ businessInfo.btnName+'&nbsp&nbsp' }}</span>
          <i class="el-icon-right"></i>
        </el-button>
      </div>

    </div>

    <div v-if="isVisible">
      <el-dialog title="告诉我们您的问题或需求：" @close="resetForm" :visible.sync="isVisible" width="70%" :close-on-click-modal="false">
        <div class="main_panel_dialog">
          <div class="form_panel">
            <div class="form_topic">
              <!-- <div class="topic_label">告诉我们您的问题或需求：</div> -->
              <div class="topic_desc">请填写如下的在线表格并提交，我们将尽快通过各种方式与您联系。谢谢！</div>
            </div>
            <div class="form_main">
              <el-form ref="form" :model="formInfo">
                <el-row class="form_row">
                  <el-col class="form_col" :span="8">
                    <el-form-item prop="name" :rules="[
                  { required: true, message: '请输入姓名', trigger: 'blur' },
                ]">
                      <el-input v-model="formInfo.name">
                        <div class="form_label" slot="prefix">
                          姓名:
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col class="form_col" :span="8">
                    <el-form-item prop="companyName" :rules="[
                  { required: true, message: '请输入您的公司', trigger: 'blur' },
                ]">
                      <el-input v-model="formInfo.companyName">
                        <div class="form_label" slot="prefix">
                          您的公司：
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col class="form_col" :span="8">
                    <el-form-item prop="positionName" :rules="[
                  { required: true, message: '请输入您的职位', trigger: 'blur' },
                ]">
                      <el-input v-model="formInfo.positionName">
                        <div class="form_label" slot="prefix">
                          您的职位：
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row class="form_row">
                  <el-col class="form_col" :span="8">
                    <el-form-item prop="telNum" :rules="[
                  { required: true, message: '请输入联系电话', trigger: 'blur' },
                ]">
                      <el-input v-model="formInfo.telNum">
                        <div class="form_label" slot="prefix">
                          联系电话:
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col class="form_col" :span="8">
                    <el-form-item prop="eMail" :rules="[
                  { required: true, message: '请输入电子邮箱', trigger: 'blur' },
                ]">
                      <el-input v-model="formInfo.eMail">
                        <div class="form_label" slot="prefix">
                          电子邮箱：
                        </div>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row class="form_row">
                  <el-col class="form_col" :span="24">
                    <el-form-item prop="opinion" :rules="[
                  { required: true, message: '请填写您的业务相关问题或相关信息', trigger: 'blur' },
                ]">
                      <el-input placeholder="请填写您的业务相关问题或相关信息" v-model="formInfo.opinion" type="textarea" :rows="4">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="form_desc">
              赛赋医药承诺对您在本网页下提供的任何信息，包括您的个人信息将按照相关的法律法规规定进行严格保密。
            </div>
            <div class="tool_bar">
              <el-button type="primary" @click="sumbitFn">
                <span class="btn_label">提交</span>
                <i class="el-icon-right"></i>
              </el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  components: {
  },
  // 定义属性
  data() {
    return {
      isVisible: false,
      formInfo: {
        name: "",
        telNum: "",
        companyName: "",
        positionName: "",
        eMail: "",
        countryName: "",
        opinion: ""
      },
      "baseInfos": [],
      "businessInfo": {}
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    articleInfo() {
      let lang = this.$i18n.locale
      return this.infoData[lang] || ""
    },
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    // 获取图片
    getPic(name) {
      return require(`./images/${name}.png`)
    },
    // 表单弹框
    contactUs() {
      this.isVisible = true
    },
    // 提交表单
    sumbitFn() {
      let _this = this
      this.$refs.form.validate((b, o) => {
        if (b) {
          _this.$http.post(`${window.SEVERURL}/safeglp/generator/cooperation/save`, {
            "name": _this.formInfo.name,
            "contactNumber": _this.formInfo.telNum,
            "company": _this.formInfo.companyName,
            "position": _this.formInfo.positionName,
            "email": _this.formInfo.eMail,
            "country": _this.formInfo.countryName,
            "description": _this.formInfo.opinion,
            "additionalInfo": "",
            "createdAt": new Date().toLocaleString().replace(/\//g, "-")
          }).then(res => {
            if (res.data.code == 0) {
              this.$notify({
                title: '成功',
                message: '您的业务相关问题或相关信息已经提交成功，我们会及时关注并答复',
                type: 'success'
              })
            } else {
              this.$notify({
                title: '失败',
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        } else {
          console.log(o)
        }
      })
    },
    // 获取页面数据
    getData() {
      let _this = this
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=companyInfos`).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          // 获取配置数据
          let jsonStr = res.data.page ? res.data.page.configs : "{}"
          let jsonStrPure = jsonStr.replace(/\n/g, "").replace(/\r/g, "")
          let configData = JSON.parse(jsonStrPure)

          // 处理配置数据
          _this.baseInfos = configData.baseInfos
        } else {
          _this.$notify({
            title: '失败',
            message: "公司信息数据请求失败",
            type: 'error'
          })
        }
      })
      _this.$http.get(`${window.SEVERURL}/safeglp/generator/configs/queryByType?primaryTitle=home&secondaryTitle=businessCooperation`).then(res => {
        if (res.status == 200 && res.data.code == 0) {
          // 获取配置数据
          let jsonStr = res.data.page ? res.data.page.configs : "{}"
          let jsonStrPure = jsonStr.replace(/\n/g, "").replace(/\r/g, "")
          let configData = JSON.parse(jsonStrPure)

          // 处理配置数据
          _this.businessInfo = configData
          if (_this.businessInfo.image) {
            _this.businessInfo.image = window.PICSEVERURL + '/' + _this.businessInfo.image
          }
          if (_this.businessInfo.imageRight) {
            _this.businessInfo.imageRight = window.PICSEVERURL + '/' + _this.businessInfo.imageRight
          }
        } else {
          _this.$notify({
            title: '失败',
            message: "商务合作信息数据请求失败",
            type: 'error'
          })
        }
      })
    },
    resetForm() {
      this.$refs['form'].resetFields()
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getData()
  },
  beforeCreate() { }, // 生命周期 - 创建之前
  beforeMount() { }, // 生命周期 - 挂载之前
  beforeUpdate() { }, // 生命周期 - 更新之前
  updated() { }, // 生命周期 - 更新之后
  beforeDestroy() { }, // 生命周期 - 销毁之前
  destroyed() { }, // 生命周期 - 销毁完成
  activated() { }, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
.contact_container {
  display: flex;
  flex: 1;
  align-items: center; /* 使内容底部对齐 */
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.contact_image {
  // margin-left: 30px; /* 调整图片和文字之间的间距 */
}

.contact_info {
  width: 50%;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.contact_banner {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner_img {
  width: 100%;
  // height: 100%;
}
.info_detail {
  display: flex;
  align-items: center;
}
.info_image {
  height: 30px;
  width: 30px;
  margin: 0 auto;
}
.icon_bg {
  width: 50px; /* 宽度 */
  height: 50px; /* 高度 */
  background-color: #efefef; /* 背景颜色 */
  border-radius: 50%; /* 圆形 */
  display: flex;
  justify-self: center;
  align-items: center;
}
.contact_tel {
  line-height: 40px;
  font-family: PingFang SC, PingFang SC;
  margin-left: 30px;
}
.detail_label {
  color: #000000;
  line-height: 36px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
}
.detail_desc {
  font-weight: 400;
  font-size: 1.125rem;
  color: #999999;
  .address {
    display: flex;
    flex-direction: column;
    line-height: 32px;
  }
}
.contact_btn {
  margin-top: 20px;
}
/deep/ .el-input__inner {
  padding-left: 120px;
}
/deep/ .el-dialog__title {
  font-weight: 600;
  font-size: 32px;
  color: #000000;
}
/deep/.el-dialog__body {
  background: #f6f7f9;
}
/deep/ .el-dialog__header {
  background: #f6f7f9;
}
/deep/ .el-divider--horizontal {
  margin: 10px 0;
}
.business_sub_page {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .topic_panel {
    max-width: 1520px;
    min-width: 1200px;
    width: 100%;
    margin-top: 70px;
    margin-bottom: 66px;
    .topic_desc {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 42px;
      color: #222222;
      line-height: 46px;
      margin-bottom: 10px;
    }
    .topic_desc_en {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 24px;
      color: #bcbdbe;
      line-height: 28px;
      margin-bottom: 16px;
    }
    .topic_desc_line {
      width: 76px;
      height: 3px;
      background: #0086d1;
    }
  }
  .contact_us {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 32px;
    color: #000000;
    line-height: 35px;
    margin-bottom: 30px;
  }
  .main_panel {
    width: 100%;
    margin-bottom: 56px;
    max-width: 1520px;
    min-width: 1200px;
  }
  .main_panel_dialog {
    width: 100%;
    // margin-bottom: 16px;
    .form_panel {
      width: 100%;
      background: #f6f7f9;
      // padding: 55px 44px 68px 58px;
      box-sizing: border-box;
      .form_topic {
        .topic_label {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 32px;
          color: #000000;
          line-height: 35px;
          margin-bottom: 20px;
        }
        .topic_desc {
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          font-size: 18px;
          color: #333333;
          line-height: 35px;
          margin-bottom: 25px;
        }
      }
      .form_row {
        .form_col {
          padding-right: 29px;
          box-sizing: border-box;
        }
      }
      .form_label {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
        padding: 0 20px;
      }
      .form_desc {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #666666;
        line-height: 28px;
        margin-bottom: 18px;
      }
      .tool_bar {
        .btn_label {
          margin: 0 20px;
        }
      }
    }
  }
}
</style>